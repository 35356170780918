import { initializeApp } from "firebase/app";
import
  {
    browserLocalPersistence,
    getAuth,
    setPersistence,
  } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyA8SDMz3yI3Exy6E1JH5Tu0-AUk1FtzN1U",
  authDomain: "cmpnd-uat.firebaseapp.com",
  projectId: "cmpnd-uat",
  storageBucket: "cmpnd-uat.appspot.com",
  messagingSenderId: "766349391112",
  appId: "1:766349391112:web:4ed68c1adf1974ffa7a8bd",
  measurementId: "G-VENJ66R0Q3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(app);
setPersistence(firebaseAuth, browserLocalPersistence);
